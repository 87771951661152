import { Component, Input, OnInit } from '@angular/core';
import { ApService } from 'src/app/shared/services/ap.service';
import { VceVhubService } from 'src/app/shared/services/vce-vhub.service';
import { overlay_subnet_type } from 'src/app/shared/model/vce-vhub';
import { VhubVceModalComponent } from '../../vhub-vce-modal/vhub-vce-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-device-vce-vhub',
  templateUrl: './device-vce-vhub.component.html',
  styleUrls: ['./device-vce-vhub.component.scss']
})
export class DeviceVceVhubComponent implements OnInit {
  vHub_mode = false;
  vCE_mode = false;
  vHub_devices = [];
  vCE_devices = [];
  deviceOrganizations = []
  wireguards = []
  _device: any;
  role: any;
  checked = false
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this.vHub_mode = obj[0]?.templateConfig?.team?.config?.team1?.mode == 'VHUB';
    this.vCE_mode = obj[0]?.templateConfig?.team?.config?.team1?.mode == 'VCE';
    this.loadData(obj[0])
    this.checked = obj[0]?.templateConfig?.overlay_sync?.enabled
    if (this.vHub_mode) {
      let _wireguards = obj[0]['statistics']['wireguard']
      this.wireguards = Object.keys(_wireguards).map(function (wireguardIndex) {
        let desc = _wireguards[wireguardIndex]['description'];
        _wireguards[wireguardIndex]['title'] = desc? desc.substr(desc.length - 10) : "";
        let wg = _wireguards[wireguardIndex];
        return wg;
      });
    }
    this._device = obj[0];
  }
  constructor(
    private vceVhubService: VceVhubService,
    private apService: ApService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
  }

  loadData(obj) {
    if (this.vHub_mode && !this.vCE_mode) {
      this.vceVhubService.loadVceConnection(obj.mac).subscribe(res => {
        if(res.data.length > 0){
          res.data[0]["type"] = this._device.templateConfig?.team?.config?.team1?.mode
          this.vCE_devices = [...res.data]
        }
      })

      if(!this.vCE_devices && !this.vCE_devices.length){
        this.vceVhubService.getVceConfig(obj).subscribe(res => {
          this.vCE_devices = [...res]
        })
      }
    } else if (this.vCE_mode && !this.vHub_mode) {
      this.vceVhubService.loadVHubConnection(obj.mac).subscribe(res => {
        if(res.data.length > 0){
          res.data[0]["type"] = this._device.templateConfig?.team?.config?.team1?.mode
          this.vCE_devices = [...res.data]
        }
      })

      if(!this.vCE_devices && !this.vCE_devices.length){
        this.vceVhubService.getVhubConfig(obj).subscribe(res => {
          this.vCE_devices = [...res]
        })
      }
    }
  }

  openvHUBvCE() {
    const dialogRef = this.dialog.open(VhubVceModalComponent, {
      disableClose: true,
      width: '700px',
      data: this._device
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData(this._device)
    });
  }

  delete(con) {
    if (confirm('Are you sure you want to delete?')) {
      this.vceVhubService.deleteConnection(con._id).subscribe(res => {
        this.toastr.success('vCE-vHUB connection is deleted successfully');
        this.loadData(this._device)
      })
    }
  }

  toggle(con, b) {
    this.vceVhubService.toggleConnection(con._id, b).subscribe(res => {
      if (b) {
        this.toastr.success('vCE-vHUB connection is disabled successfully');
      } else {
        this.toastr.success('vCE-vHUB connection is enabled successfully');
      }
      this.loadData(this._device)
    })
  }

  onTplToggle(e) {
    this.checked = e.checked
    this.apService.toggleTemplate(this._device._id, "overlay_sync", this.checked).subscribe(res => {
      if (this._device.templateConfig && this._device.templateConfig.overlay_sync) {
        this._device.templateConfig.overlay_sync.enabled = e.checked;
      } else {
        this._device.templateConfig.overlay_sync = {
          enabled: e.checked
        }
      }
    })
  }

}
