<div class="mt-2 monitor-device-system-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab label="Ethernet Ports & VLANs">
            <hr class="y-border" />
            <mat-tab-group>
                <mat-tab label="Ethernet Ports Configuration">
                    <hr class="y-border" />
                    <mat-card>        
                        <table *ngIf="ether_ports">
                        <tbody>
                        <tr >
                        <th>Ethernet Ports</th>
                        <th *ngFor="let key of getObjectKeys(ether_ports.data)">{{key}}</th>
                        </tr>  
                        <tr>
                            <td>Mode</td>
                            <td [attr.colspan]="ether_ports.length">{{displayMode(ether_ports.mode)}}</td>
                        </tr>
                        <tr *ngIf="ether_ports.mode=='port_based_lans'">
                            <td>Network</td>
                            <td *ngFor="let key of getObjectKeys(ether_ports.data)">{{(ether_ports.data[key].network ? ether_ports.data[key].network : ether_ports.data[key].ifname)  | uppercase}}</td>
                        </tr>
                        <tr>
                            <td>Port Speed</td>
                            <td *ngFor="let key of getObjectKeys(ether_ports.data)">{{displayPortSpeed(ether_ports.data[key].port_speed)}}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td *ngFor="let key of getObjectKeys(ether_ports.data)">                        
                                <img *ngIf="ether_ports.data[key].up==1 && ['p1','p2','p3'].includes(ether_ports.data[key].ifname) && ['RRT-200','RRT-200-LTE','RRT-300','RRT-300-LTE'].includes(ether_ports.model)" src="./assets/images/RRT-200_LAN_port_up.png" class="matero-branding-logo-expanded" alt="logo" />
                                <img *ngIf="ether_ports.data[key].up==0 && ['p1','p2','p3'].includes(ether_ports.data[key].ifname) && ['RRT-200','RRT-200-LTE','RRT-300','RRT-300-LTE'].includes(ether_ports.model)" src="./assets/images/RRT-200_LAN_port_down.png" class="matero-branding-logo-expanded" alt="logo" />                        
                                <img *ngIf="ether_ports.data[key].up==1 && ['eth0','eth1','eth2','eth3'].includes(ether_ports.data[key].ifname) && ['RRT-500','RRT-500-LTE'].includes(ether_ports.model)" src="./assets/images/RRT-500_LAN_port_up.png" class="matero-branding-logo-expanded" alt="logo" />
                                <img *ngIf="ether_ports.data[key].up==0 && ['eth0','eth1','eth2','eth3'].includes(ether_ports.data[key].ifname) && ['RRT-500','RRT-500-LTE'].includes(ether_ports.model)" src="./assets/images/RRT-500_LAN_port_down.png" class="matero-branding-logo-expanded" alt="logo" />
                                <img *ngIf="ether_ports.data[key].up==1 && ['wan','wan2'].includes(ether_ports.data[key].ifname)" src="./assets/images/RRT-200_WAN1_port_up.png" class="matero-branding-logo-expanded" alt="logo" />
                                <img *ngIf="ether_ports.data[key].up==0 && ['wan','wan2'].includes(ether_ports.data[key].ifname)" src="./assets/images/RRT-200_WAN1_port_down.png" class="matero-branding-logo-expanded" alt="logo" />
                                <br/>
                                <strong *ngIf="ether_ports.data[key].up==1">1000baseT<br /> full-duplex</strong>
                                <strong *ngIf="ether_ports.data[key].up==0">no carrier</strong>
                            </td>
                        </tr>           
                        </tbody>
                        </table>
                    </mat-card>
                </mat-tab>
                <mat-tab label="LAN VLAN Configuration">
                    <hr class="y-border" />
                    <mat-card>
                        <table *ngIf="lan_info">
                            <tr>
                                <th>Network</th>
                                <th>Device</th>
                                <th>VLAN ID</th>
                                <th>Zone</th>
                                <th>IP</th>
                                <th>DHCPS</th>
                            </tr>
                            <tr *ngFor="let lan of lan_info;">
                                <td>{{lan.network}}</td>
                                <td>{{lan_ports[lan.network] && lan_ports[lan.network].length > 0 ? (lan.network | uppercase) +' Port:' : '' }} {{lan_ports[lan.network]}}</td>
                                <td>({{displayType(lan.rr_type) == "" ? lan.rr_vlan_id : displayType(lan.rr_type)}})</td>
                                <td>{{lan.rr_zone}}</td>
                                <td>{{lan.cur_ipaddr ? lan.cur_ipaddr: lan.ipaddr}}</td>
                                <td>{{displayDHCPs(lan.rr_dhcps)}}</td>
                            </tr>
                        </table>                
                    </mat-card>
                </mat-tab>
                <mat-tab label="WAN VLAN Configuration">
                    <hr class="y-border" />
                    <mat-card>
                        <table *ngIf="wan_info">
                            <tr>
                                <th>Network</th>
                                <th>Device</th>
                                <th>VLAN ID</th>
                                <th>Zone</th>
                                <th>IP</th>
                                <th>DHCPS</th>
                            </tr>
                            <tr *ngFor="let wan of wan_info;">
                                <td>{{wan.network}}</td>
                                <td>{{wan.network}} Port</td>
                                <td>({{displayType(wan.rr_type) == "" ? wan.rr_vlan_id : displayType(wan.rr_type)}})</td>
                                <td>{{wan.rr_zone}}</td>
                                <td>{{wan.cur_ipaddr ? wan.cur_ipaddr: wan.ipaddr}}</td>
                                <td>{{displayDHCPs(wan.rr_dhcps)}}</td>
                            </tr>
                        </table>                
                    </mat-card>
                </mat-tab>
            </mat-tab-group>            
        </mat-tab>
        <mat-tab label="Interfaces">
            <hr class="y-border" />
            <table>
                <tbody>
                <tr>
                  <th>Zone</th>
                  <th>State</th>
                  <th>Network</th>
                  <th>Device</th>
                  <th>Type</th>
                  <th>Protocol</th>
                  <th>Uptime</th>
                  <th>MAC</th>
                  <th>RX</th>
                  <th>RXpkts</th>
                  <th>TX</th>
                  <th>TXpkts</th>
                  <th>IPv4</th>
                  <th>IPv6</th>
                </tr>
            
                <tr *ngFor="let ifc of interfaces" [hidden]="!interfaces.length">
                  <td [ngStyle]="{'background': ifc.zone_color}" class="uppercase-text">{{ifc.zone}}</td>
                  <td>
                    <span style='color: #45d645;' *ngIf="ifc.status == 'Up'">{{ifc.status}}</span>
                    <span style='color: #b0b0b0;' *ngIf="ifc.status == 'Down'">{{ifc.status}}</span>
                  </td>
                  <td>{{ifc.network}}</td>
                  <td>{{ifc.ifname}}</td>
                  <td>{{ifc.type}}</td>
                  <td>{{ifc.proto}}</td>
                  <td>{{ifc.uptime_str}}</td>
                  <td style="text-transform: uppercase; ">{{ifc.mac}}</td>
                  <td>{{ifc.rx}}</td>
                  <td>{{ifc.rxpkt}}</td>
                  <td>{{ifc.tx}}</td>
                  <td>{{ifc.txpkt}}</td>
                  <td>{{ifc.address}}</td>
                  <td style="text-transform: uppercase;">{{ifc.address6}}</td>
                </tr>
                </tbody>
              </table>
        </mat-tab>
        <mat-tab label="Active DHCP">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>Hostname</th>
                        <th>
                            <span *ngIf="dhcp_leases?.length">IPv4-Address</span>
                            <span *ngIf="dhcp6_leases?.length">IPv6-Address</span>
                        </th>
                        <th>MAC Address</th>
                        <th>Leasetime Remaining</th>
                    </tr>
                    <tr *ngFor="let dhcp of dhcp_leases" [hidden]="!dhcp_leases.length">
                        <td>{{dhcp.hostname}}</td>
                        <td>{{dhcp.ipaddr}}</td>
                        <td>{{dhcp.macaddr}}</td>
                        <!-- <td>{{timestampByHrs(dhcp.expires)}}</td> -->
                        <td>{{dhcp.expires}}</td>
                    </tr>
                    <tr *ngFor="let dhcp6 of dhcp6_leases" [hidden]="!dhcp6_leases.length">
                        <td>{{dhcp6.hostname}}</td>
                        <td>{{dhcp6.ipaddr}}</td>
                        <td>{{dhcp6.macaddr}}</td>
                        <!-- <td>{{timestampByHrs(dhcp6.expires)}}</td> -->
                        <td>{{dhcp6.expires}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="System">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>System Type</th>
                        <th>Mac Address</th>
                        <th colspan="2">Machine ID</th>
                    </tr>
                    <tr>
                        <td>{{statusType}}</td>
                        <td>{{mac}}</td>
                        <td colspan="2">{{machineId}}</td>
                    </tr>
                    <tr>
                        <th>CPU Count</th>
                        <th>NIC Count</th>
                        <th colspan="2">Firmware</th>
                    </tr>
                    <tr>
                        <td>{{cpuCount}}</td>
                        <td>{{nicCount}}</td>
                        <td colspan="2">{{firmware}}</td>
                    </tr>
                    <tr *ngIf="system">
                        <th>Uptime</th>
                        <th>Load Avg</th>
                        <th>CPU Usage</th>
                        <th>Memory Usage</th>
                    </tr>
                    <tr *ngIf="system">
                        <td>{{uptime}} {{ system.uptime}}</td>
                        <td>{{load_avg}}</td>
                        <td>{{system.cpu_usage | number: '1.2-2'}}%</td>
                        <td>{{system.mem_usage | number: '1.2-2'}}%</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="Memory">
            <hr class="y-border" />
            <table class="memory">
                <tbody>
                    <tr>
                        <th>Total Available</th>
                        <td>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="availableBytes && {'width': available + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                <span>{{availableBytes}}/{{totalBytes}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Used</th>
                        <td>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="usedBytes && {'width': used + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                <span>{{usedBytes}}/{{totalBytes}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Buffered</th>
                        <td>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="bufferBytes && {'width': buffer + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                <span>{{bufferBytes}}/{{totalBytes}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Cached</th>
                        <td>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="cachedBytes && {'width': cached + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                <span>{{cachedBytes}}/{{totalBytes}}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="Network">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>Active Connections</th>
                    </tr>
                    <tr>
                        <td>{{connection_count}}/{{connection_max}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>        
        <mat-tab label="WiFi Clients">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>Hostname</th>
                        <th>MAC Address</th>
                        <th>Signal</th>
                        <th>RX Info</th>
                        <th>TX Info</th>
                    </tr>
                    <tr *ngFor="let assoc of assoc_list" [hidden]="!assoc_list.length">
                        <td>{{assoc.hostname}}</td>
                        <td>{{assoc.mac}}</td>
                        <td>{{assoc.signal}}</td>
                        <td>{{assoc.rx_info}}</td>
                        <td>{{assoc.tx_info}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="General Settings">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>Timezone</th>
                    </tr>
                    <tr>
                        <td>{{timezone}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="Logging">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>System log buffer size</th>
                        <th>External system log server</th>
                        <th>External system log server port</th>
                        <th>External system log server protocol</th>
                        <th>Write system log to file</th>
                        <th>Log output level</th>
                        <th>Cron Log Level</th>
                    </tr>
                    <tr>
                        <td>{{logging?.log_size}}</td>
                        <td>{{logging?.log_ip}}</td>
                        <td>{{logging?.log_port}}</td>
                        <td>{{logging?.log_proto}}</td>
                        <td>{{logging?.log_file}}</td>
                        <td>{{logging?.conloglevel}}</td>
                        <td>{{logging?.cronloglevel}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="Asset Groups">
          <hr class="y-border" />
          <table *ngIf="ras">
            <tbody *ngIf="ras.ras_asset">
            <tr>
              <th>Asset Name</th>
              <th>Asset IPs</th>
            </tr>
            <tr *ngFor="let asset of ras.ras_asset">
              <td>{{asset.name}}</td>
              <td>
                <span *ngFor="let entries of asset.entry; let last = last">
                    {{entries}}<span *ngIf="!last">, </span>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </mat-tab>       
    </mat-tab-group>
</div>
